import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {authGuardFn, trackGuardFn} from '@isifid/core';
import {GiftCardsComponent} from './gift-cards/gift-cards.component';
import {HomeComponent} from './home/home.component';
import {HowtoComponent} from './howto/howto.component';
import {SupportComponent} from './support/support.component';
import {NotFoundComponent} from './not-found/not-found.component';
import { SupportFaqComponent } from './support/faq/faq.component';
import { SupportDemoComponent } from './support/demo/demo.component';
import { SupportNosConseilsComponent } from './support/nos-conseils/nos-conseils.component';
import { SupportContactComponent } from './support/contact/contact.component';

const routes: Routes = [
    {
        path: 'gift-cards',
        canActivate: [authGuardFn, trackGuardFn],
        component: GiftCardsComponent
    },
    {
        path: 'home',
        canActivate: [authGuardFn, trackGuardFn],
        component: HomeComponent
    },
    {
        path: 'how-to',
        canActivate: [authGuardFn, trackGuardFn],
        component: HowtoComponent
    },
    {
        path: 'not-found',
        canActivate: [authGuardFn, trackGuardFn],
        component: NotFoundComponent
    },
    {
        path: 'support',
        canActivate: [authGuardFn, trackGuardFn],
        component: SupportComponent,
        children: [
            {
                path: 'demo',
                component: SupportDemoComponent
            },
            {
                path: 'faq',
                component: SupportFaqComponent
            },
            {
                path: 'nos-conseils',
                component: SupportNosConseilsComponent,
                data: {roles: 'GIFT_MANAGER'},
            },
            {
                path: 'contact',
                component: SupportContactComponent
            },
            {
                path: '',
                redirectTo: 'demo',
                pathMatch: 'full'
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MiscRoutingModule {
}
