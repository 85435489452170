import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GiftService} from '../../../shared/services/gift.service';
import {AuthenticationService, CacheService, UserService} from '@isifid/core';
import {BootstrapService} from '../../../shared/services/bootstrap.service';
import {switchMap} from 'rxjs';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    standalone: false
})
export class AuthComponent implements OnInit {
    loading = false;
    private clientId: number;
    private provider: string;
    private routeToRedirect: string;
    private token: string;
    private uuid: string;
    private lastLoggedInAt: number;

    constructor(
        private readonly giftService: GiftService,
        private readonly bootstrapService: BootstrapService,
        private readonly authenticationService: AuthenticationService,
        private readonly cacheService: CacheService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.parseUrl();
        this.dispatch();
    }

    // Parse the url, no args are available when auth component is called
    parseUrl(): void {
        this.route.queryParams.subscribe(params => {
            this.provider = params.provider;
            this.token = params.token;
            this.clientId = Number(params.clientId);
            this.uuid = params.uuid;
            this.routeToRedirect = params.route;
            this.lastLoggedInAt = params.ts ? Number(params.ts) * 1000 : null;

            if (this.lastLoggedInAt && new Date(this.lastLoggedInAt) > new Date(this.userService.getUser()?.lastLoggedInAt ?? null)) {
                this.routeToRedirect = '/account/favorite';
            }
        });
    }

    // Dispatch among several SSO ways to connect
    dispatch(): void {
        // We need at least a provider and a token
        if (!this.provider || !this.token) return this.redirectTo('/logout');

        switch (this.provider) {
            case 'isifid':
            // Logout if the client id is not the same as the one in the url
                if (this.userService.getUser() && this.userService.getUser().clientId !== this.clientId) {
                    this.authenticationService.logout(false, true);
                }
                this.isifid();
                break;
            case 'legacy':
            case 'purl':
                this.purl();
                break;
            case 'ce':
                break;
            case 'bp':
                break;
            case 'ca':
                break;
            default:
                this.redirectTo('/logout');
                break;
        }
    }

    purl(): void {
        // Store the token, so we can reuse it later (add to favorite)
        this.cacheService.addPermanentContent('pUrlToken', this.token, false);
        this.bootstrapService.giftUserUuid = this.uuid;
        // Get tokens
        this.authenticationService.loginByUserToken(this.token)
            .pipe(switchMap(tokens => this.userService.initUser(tokens.accessToken)))
            .subscribe({
                next: () => this.initAfterAuth(),
                error: () => {
                    console.error('Error while init with token');
                    this.redirectTo('/logout');
                }
            });
    }

    isifid(): void {
        // Check we have a valid clientId
        const clientId = Number(this.clientId);
        if (isNaN(clientId) || clientId === 0) return this.redirectTo('/logout');

        // init user
        this.authenticationService.loginBySso('admin', this.token, clientId.toString())
            .pipe(switchMap(tokens => this.userService.initUser(tokens.accessToken)))
            .subscribe({
                next: () => this.initAfterAuth(),
                error: () => {
                    console.error('Error while login by sso');
                    this.redirectTo('/logout');
                }
            });
    }

    initAfterAuth(): void {
        this.bootstrapService.isInitialised.subscribe({
            next: (initialised) => {
                if (!initialised) return;

                let returnUrl = this.cacheService.getContent('return_url', false);
                if (this.routeToRedirect) returnUrl = this.routeToRedirect;
                if (!returnUrl || returnUrl.indexOf('/auth') === 0) returnUrl = '/home';
                this.cacheService.deleteContent('return_url');
                this.redirectTo(returnUrl);
            }
        });
    }

    redirectTo(path: string): void {
        // Only allow to navigate path if account is complete or to logout
        // Force to account edition if missing infos
        if (this.giftService.isAccountComplete || path === '/logout') this.router.navigate([path]).then();
        else this.router.navigate(['/account/user']).then();
    }
}
