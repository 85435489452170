import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {OperationsService} from '../../../shared/services/operations.service';
import {StatsService} from '../../../shared/services/stats.service';
import {DateService} from '@isifid/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-operations-tracking',
    templateUrl: './operations-tracking.component.html',
    standalone: false
})
export class OperationsTrackingComponent implements OnInit {
    activeOperations: Array<any> = [];
    dataSource = new MatTableDataSource<any>();
    displayedColumns: Array<string>;
    loading: boolean = true;
    @ViewChild(MatSort) sortOperations: MatSort;

    constructor(
        private cdRef: ChangeDetectorRef,
        private readonly dateService: DateService,
        private readonly operationsService: OperationsService,
        private readonly statsService: StatsService
    ) {
        this.displayedColumns = ['name', 'consumption', 'startDate', 'endDate'];
    }

    ngOnInit(): void {
        // Get all operations
        const allOperations = this.operationsService.operations;
        // Only active operations
        this.activeOperations = allOperations.filter(o => o.operationType.name !== 'SPONSORSHIP' && o.status === 'active');
        // Get Stats for operations
        if (this.activeOperations.length) this.getStats();
        this.dataSource = new MatTableDataSource<unknown>(this.activeOperations);
        this.cdRef.detectChanges();
        this.dataSource.sort = this.sortOperations;
        this.loading = false;
    }

    private getStats() {
        this.activeOperations.forEach((operation) => {
            const filters = {
                start: this.dateService.computeDate(operation.startDate, 'yyyy-MM-dd'),
                end: this.dateService.computeDate(operation.endDate || new Date(), 'yyyy-MM-dd', 1)
            };
            this.statsService.getByOperationId(operation.id, [], filters).subscribe({
                //    Add consumption for each operation
                next: (stats) => operation['consumption'] = stats.rewardStats?.length,
                error: () => console.error(`error while getting operation : ${operation.id}`),
                complete: () => this.loading = false
            });
        });
    }
}
