import {Component} from '@angular/core';
import {GiftService} from '../../../shared/services/gift.service';
import {Client} from '@isifid/core';
import {environment} from '../../../../environments/environment';
import {UIService} from '../../../shared/services/ui.service';

@Component({
    selector: 'app-gift-cards',
    templateUrl: './gift-cards.component.html',
    standalone: false
})
export class GiftCardsComponent {
    client: Client;
    env = environment;

    constructor(
        private readonly giftService: GiftService,
        public readonly uiService: UIService
    ) {
        this.client = this.giftService.client;
    }

    getGiftSiteUrl(premium?: boolean): string {
        let domain: string;
        if (this.giftService.client.configuration) domain = JSON.parse(this.giftService.client.configuration)?.demoDomain ?? this.giftService.client.domain;
        else domain = this.giftService.client.domain;

        if (premium) return `https://${domain}/demo?rewardTypeId=2`;
        return `https://${domain}/demo?rewardTypeId=1`;
    }
}
