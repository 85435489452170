<h4 class="text-center mb-5">
    Nos conseils pour un parrainage réussi
</h4>

<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Est-ce difficile pour un client de parrainer ?
        </mat-expansion-panel-header>
        <p class="small">
            Beaucoup de gens sont insatisfaits de leurs relations avec leurs banques. Il suffit que vos clients disent du bien de vous pour créer des ouvertures.<br>
            Ils en parleront d’autant plus volontiers s’il y a une récompense à la clé.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Comment inciter un client à parrainer ?
        </mat-expansion-panel-header>
        <p class="small">
            La première incitation est qu’il soit satisfait de vos services. Si ce n’est pas le cas, inutile d’espérer le moindre parrainage de sa part.<br>
            La deuxième incitation est de lui parler du parrainage, de lui dire tout simplement que, s’il est satisfait de vos services, le meilleur service qu’il puisse vous rendre c’est d’en parler autour de lui, quand l’occasion se présente.<br>
            La troisième incitation est la simplicité du process, il suffit qu’il indique à ses proches un code qu’il a lui-même choisi (exemple: son surnom) et que le filleul indique ce code au conseiller  lors de l’ouverture du compte. <br>
            La quatrième incitation est la récompense pour le parrain comme pour le filleul.<br>
            Enfin, ne pas oublier d’inscrire votre client sur la plateforme de parrainage, cela prend une minute.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Comment votre client va-t-il parvenir à amener un ou plusieurs proches à ouvrir un compte dans votre établissement ?
        </mat-expansion-panel-header>
        <p class="small">
            Par sa satisfaction du service rendu par son(sa) conseiller(e), <br>
            par la simplicité du process, <br>
            par la récompense.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Existe-t-il un profil type du bon parrain ?
        </mat-expansion-panel-header>
        <p class="small">
            Un client satisfait qui a beaucoup d’interactions sociales et un certain leadership mais le plus important c’est d’inscrire tous les clients dont vous supposez qu’ils sont satisfaits de vous.<br>
            Une fois inscrit, nous nous chargeons d’en faire des parrains efficaces.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Pourquoi faut-il inscrire les clients sur la plateforme de parrainage ?
        </mat-expansion-panel-header>
        <p class="small">
            Pour qu’ils disposent d’un code Parrain qu’ils puissent communiquer à leurs proches, code qu’ils peuvent personnaliser (avec leur surnom par exemple).<br>
            Pour qu’ils puissent faire passer un message de parrainage à leur entourage, par le canal de leur choix (mail, SMS, post…)
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Est-ce nécessaire de récompenser le parrain et le filleul ?
        </mat-expansion-panel-header>
        <p class="small">
            Oui, vous ne pouvez pas demander quelque chose à votre client sans le remercier et, de son côté, il sera plus à l’aise vis à vis de son filleul s’il lui procure une récompense.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Comment faire en sorte que le filleul aille jusqu’au bout ?
        </mat-expansion-panel-header>
        <p class="small">
            Aujourd’hui, le filleul reçoit un message de son parrain avec un lien de prise de RV.<br>
            Une nouvelle option lui permet de se faire rappeler par le conseiller (développement en cours).
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Comment faire en sorte de valoriser le parrain (notion de VIP) ?
        </mat-expansion-panel-header>
        <p class="small">
            « Viens de ma part, tu seras bien reçu! » Le parrain recommande un de ses proches à son(sa) conseiller(e), à charge pour lui (elle) de l’accueillir chaleureusement.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Comment faire en sorte de valoriser le filleul (notion de VIP) ?
        </mat-expansion-panel-header>
        <p class="small">
            Le filleul doit avoir le sentiment qu’il va être particulièrement bien accueilli du fait qu’il a été recommandé par son parrain.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Le parrainage est-il un axe de conquête important ?
        </mat-expansion-panel-header>
        <p class="small">
            Oui, lorsqu’il est bien pratiqué, il représente entre un quart et un tiers des ouvertures de comptes.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Le parrainage prend-il beaucoup de temps ?
        </mat-expansion-panel-header>
        <p class="small">
            Non, en parler aux clients satisfaits et les inscrire prend moins de 2 minutes.<br>
            Accueillir un filleul ne prend pas plus longtemps.
        </p>
    </mat-expansion-panel>
</mat-accordion>