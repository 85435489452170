@if (supportForm) {
    <h4 class="text-center mb-5">
        Contacter le support Isifid
    </h4>

    <div class="mb-3">
        <p>Pour nous contacter, trois solutions s'offrent à vous :</p>
        <ul>
            <li class="small ">Vous pouvez remplir le formulaire ci-dessous</li>
            <li class="small ">Vous pouvez nous contacter directement via le chat en bas à droite de votre écran</li>
            <li class="small ">Vous pouvez nous écrire à gift&#64;isifid.com</li>
        </ul>
    </div>
    <form (ngSubmit)="submit()" [formGroup]="supportForm" class="m-0 p-0 text-end">
        <div class="row my-3">
            <mat-form-field appearance="fill" class="col">
                <mat-label>Votre message : </mat-label>
                <textarea matInput
                          formControlName="message"
                          id="message"
                          rows="10"></textarea>
            </mat-form-field>
        </div>
        
        @if (!loading) {
            <button [disabled]="supportForm.invalid"
                    class="btn btn-validate">
                    Envoyer
            </button>
        } @else {
            <button class="btn btn-validate" disabled>
                <em aria-hidden="true" class="fas fa-spinner fa-spin"></em> envoi en cours
            </button>
        }
    </form>

}
