import {Component} from '@angular/core';

@Component({
    selector: 'app-consumers-search',
    templateUrl: './search.component.html',
    standalone: false
})

export class SearchComponent {
}
