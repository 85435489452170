<div class="container-fluid m-0 text-center">

    <div class="my-5">
        <h1>Informations de votre compte</h1>
    </div>

    @if (isCompleteAtLoading && !userService.hasRole('GIFT_HQ')) {
        <div class="row my-5">
            <div class="col text-center">
                Vous pouvez <a class="pb-3" routerLink="/account/favorite">mettre en favori</a> votre lien de connexion automatique
            </div>
        </div>
    }

    @if (userService.hasRole('GIFT_HQ') && hierarchyLevelForm) {
        <div class="mb-5">
            <form (ngSubmit)="updateRole()" [formGroup]="hierarchyLevelForm">
                <div class="row my-2">
                    <div class="col-6 col-md-5 col-lg-4 col-xl-3 mx-auto">
                        <div class="pt-4">
                            <span>Échelon hiérarchique</span>
                            <select class="form-select form-select-lg" (change)="updateRole()" formControlName="levelId">
                                @for (level of giftService.hierarchicalLevels; track level.id) {
                                    <option [value]="level.id">{{level.title}}</option>
                                }
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        @if (!loading) {
                            <button class="btn btn-validate"
                                    data-cy="submit"
                                    name="button"
                                    type="submit">
                                Mettre à jour
                            </button>
                        } @else {
                            <button class="btn btn-validate mt-2" disabled>
                                <i aria-hidden="true" class="fas fa-spinner fa-spin"></i>
                            </button>
                        }
                    </div>
                </div>
            </form>
        </div>
    }

    @if (!isCompleteAtLoading) {
        <p class="h4 mb-5 text-center">
            Pour activer votre compte, merci de compléter les informations ci-dessous.
        </p>
    }

    @if (giftUserForm) {

        <form (ngSubmit)="update()" [formGroup]="giftUserForm">
            <div class="row my-2 px-2">
                <div class="col col-lg-5 mx-lg-auto">
                    <mat-form-field class="my-3">
                        <mat-label>Email</mat-label>
                        <input matInput
                               formControlName="email"
                               id="email"
                               type="email"
                               maxlength="30" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row my-2 px-2">
                <div class="col col-lg-5 mx-lg-auto">
                    <mat-form-field class="my-3">
                        <mat-label>Prénom</mat-label>
                        <input matInput
                               formControlName="firstName"
                               id="firstName"
                               type="text"
                               maxlength="30"
                               (blur)="capitalizeInput($event)"/>
                    </mat-form-field>
                </div>
            </div>
            <div class="row my-2 px-2">
                <div class="col col-lg-5 mx-lg-auto">
                    <mat-form-field class="my-3">
                        <mat-label>Nom</mat-label>
                        <input matInput
                               formControlName="lastName"
                               id="lastName"
                               type="text"
                               maxlength="30"
                               (blur)="capitalizeInput($event)"/>
                    </mat-form-field>
                </div>
            </div>
            <div class="row my-2 px-2">
                <div class="col col-lg-5 mx-lg-auto">
                    <mat-form-field class="my-3">
                        <mat-label>N° de téléphone professionnel</mat-label>
                        <input matInput
                               formControlName="phone"
                               id="phone"
                               type="tel"
                               maxlength="20"/>
                    </mat-form-field>
                </div>
            </div>

            @if (this.giftUser?.branchList?.length < 2 && ['GIFT_ADVISOR', 'GIFT_MANAGER'].includes(this.level?.role)) {
                <div class="row my-2 px-2">
                    <div class="col col-lg-5 mx-lg-auto">
                        <mat-form-field class="my-3">
                            <mat-label>Code agence ou point de vente (en chiffres)</mat-label>
                            <input matInput
                                   formControlName="branch"
                                   id="branch"
                                   type="text"/>
                        </mat-form-field>
                    </div>
                </div>
            }

            @if (isCompleteAtLoading && giftUserForm.get('sponsorshipReportPeriodicity').value) {
                <div class="row my-2 px-2">
                    <div class="col col-lg-5 mx-lg-auto">
                        <mat-form-field>
                            <mat-label>Périodicité du rapport parrainage</mat-label>
                            <mat-select formControlName="sponsorshipReportPeriodicity">
                                <!--<mat-option value="weekly">chaque semaine</mat-option>-->
                                <mat-option value="monthly">chaque mois</mat-option>
                                <!--<mat-option value="quaterly">chaque trimestre</mat-option>-->
                                <mat-option value="never">jamais</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            }

            <div class="row mt-4 px-2">
                <div class="col col-lg-5 mx-lg-auto">
                    @if (!loading) {
                        <button class="btn btn-validate mt-2"
                                data-cy="submit"
                                name="button"
                                type="submit">
                            Mettre à jour
                        </button>
                    } @else {
                        <button class="btn btn-validate mt-2" disabled>
                            <i aria-hidden="true" class="fas fa-spinner fa-spin"></i>
                        </button>
                    }
                </div>
            </div>
        </form>

        @if (user?.firstName && userService.hasRole('GIFT_ADVISOR') || userService.hasRole('GIFT_MANAGER')) {
            <div class="row mt-5">
                <a (click)="openPersonalLinkDialog()">
                    <span>Vous n'êtes pas {{user?.firstName}} ?</span>
                </a>
            </div>
        }
    }

    @if (!giftUserForm && !loading && !userService.hasRole('GIFT_HQ')) {
        <div>
            Merci, informations mises à jour
        </div>
    }
</div>

