import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Product} from '../../../shared/models/product.model';
import {GiftService} from '../../../shared/services/gift.service';
import {OperationsService} from '../../../shared/services/operations.service';
import {Router} from '@angular/router';
import {UIService} from '../../../shared/services/ui.service';
import {
    AuthenticationService,
    CacheService,
    ConfigurationService,
    Credits,
    MsServicesGiftService,
    Offer,
    Operation,
    UserService
} from '@isifid/core';
import {SponsorshipService} from '../../../shared/services/sponsorship.service';
import {concat, of, switchMap, tap, toArray} from 'rxjs';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent implements OnInit {
    loading = true;
    credits: Array<Credits>;
    operationsWithoutPlatform: Array<Operation & { budgetRewardAvailable?: number | null }> = [];
    operationsWithPlatform: Array<Operation & { budgetRewardAvailable?: number | null }> = [];
    products: Array<Product>;
    maxProductsToDisplay = 6;
    slides = [];
    sponsorshipOffers: Array<Offer & { amount: number }>;

    constructor(
        private readonly msServicesGiftService: MsServicesGiftService,
        public readonly configurationService: ConfigurationService,
        private readonly operationsService: OperationsService,
        private readonly cacheService: CacheService,
        private readonly location: Location,
        private readonly authenticationService: AuthenticationService,
        public readonly giftService: GiftService,
        public readonly sponsorshipService: SponsorshipService,
        public readonly uiService: UIService,
        public readonly userService: UserService,
        public router: Router
    ) {
        if (!this.giftService.settings || !this.userService.getUser()) {
            this.authenticationService.logout();
        }
    }

    ngOnInit() {
        this.loading = true;
        this.replaceUrl();
        // If no reward with cash add banner
        if (!this.uiService.rewardWithCashOnly) this.getBanners();

        this.getProducts();
        this.initOperations().subscribe(() => this.loading = false);
    }

    private initOperations() {
        return this.msServicesGiftService.getAllCredits()
            .pipe(
                switchMap(credits => {
                    this.credits = credits;
                    const operations = [
                        // Operations with no consumerCreationProcess: platform and no operations type : internal
                        ...this.operationsService.getOperations(false, true, false)
                    ];

                    const observers = operations.map((operation: Operation & {
                        budgetRewardAvailable?: number | null
                    }) => {
                        return this.operationsService.getOffersByOperationId(operation.id)
                            .pipe(
                                tap(offers => {
                                    operation.offers = this.setOffers(offers)
                                    operation.budgetRewardAvailable = this.getBudgetRewardAvailable(operation.id);
                                    operation.consumerCreationProcess === 'platform' ?
                                        this.operationsWithPlatform.push(operation) :
                                        this.operationsWithoutPlatform.push(operation);
                                })
                            );
                    });
                    return concat(...observers).pipe(toArray());
                }),
                switchMap(() => {
                    // Sort operationsWithPlatform
                    this.operationsWithPlatform.sort((a, b) => {
                        if (a.budgetRewardAvailable === null && b.budgetRewardAvailable !== null) return 1;
                        else if (a.budgetRewardAvailable !== null && b.budgetRewardAvailable === null) return -1;
                        else return a.name.localeCompare(b.name);
                    });
                    // Init sponsorship if any
                    return !this.sponsorshipService.hasSponsorship ?
                        of(null) :
                        this.operationsService.getOffersByOperationId(this.sponsorshipService.operation.id)
                            .pipe(
                                tap((offers: Array<Offer & { amount: number }>) => this.sponsorshipOffers = offers)
                            );
                })
            );
    }

    private setOffers(offers) {
        return offers
        .filter(s => s.status === 'active' && Number(s.amount) > 0)
        .sort((a, b) => a.amount === b.amount ? a.name.localeCompare(b.name) : (Number(a.amount) - Number(b.amount)));
    }

    hasEnoughCredits(operationId): boolean {
        const operation = [...this.operationsWithoutPlatform, ...this.operationsWithPlatform]
            .find(o => o.id === operationId);
        // Has enough credit if the operation is not budget controlled
        if (!operation.budget) return true;

        if (this.userService.hasRole('GIFT_HQ')) return true;

        // Get the matching credit
        const budget = this.giftService.budgets.find(b => b.operationId === operationId);
        const credit = this.credits.find(c => c.budgetId === budget.id);
        // If no credit, the user doesn't have required level
        if (!credit) return true;

        // Get the first offer that is possible to reward
        // If none, the user doesn't have enough credit
        const offerToReward = operation.offers.find(o => o.amount < credit.amountRemaining);

        return !!offerToReward;
    }

    private getProducts(): void {
        if (this.uiService.rewardWithCash) return;

        if (this.uiService.rewardWithPremium) this.products = this.uiService.getProductsPremium();
        else this.products = this.uiService.getProductsRegular();

        this.products.splice(this.maxProductsToDisplay - 1, this.products.length - this.maxProductsToDisplay);
    }

    getMarketplaceUrl() {
        let domain: string;
        if (this.giftService.client.configuration) domain = JSON.parse(this.giftService.client.configuration)?.demoDomain ?? this.giftService.client.domain;
        else domain = this.giftService.client.domain;

        let url = `https://${domain}/demo?amount=50`;

        if (this.uiService.rewardWithPlatinum) url = url + `&rewardTypeId=3`;
        else if (this.uiService.rewardWithPremium) url = url + `&rewardTypeId=2`;
        else url = url + `&rewardTypeId=1`;

        return url;
    }

    isOfferForSponsored(offer: Offer): boolean {
        const configuration = JSON.parse(offer.configuration);
        return configuration?.consumerType?.indexOf('sponsored') !== -1;
    }

    private getBanners(): void {
        const operations = this.operationsService.getOperations(true, true, true);
        const operationsTypes = [...new Set(operations.reduce((acc, ope) => acc.concat(ope.operationType.id), []))];

        this.msServicesGiftService.getAllBanners([], {status: 'active', clientId: this.giftService.settings.clientId})
            .subscribe({
                next: banners => {
                    this.slides = banners
                        // Filter banner to keep active operation with matching operation type
                        .filter(b => b.operationTypeId && b.operationTypeId !== -1 ? operationsTypes.includes(b.operationTypeId) : true)
                        .map(b => b.srcUrl);
                },
                error: error => console.error(error)
            });
    }

    private getBudgetRewardAvailable(operationId: any): number | undefined {
        const budgets = this.giftService.budgets.filter(s => s.operationId === operationId);
        if (!budgets.length) return null;
        const credit = this.credits.find(c => c.budgetId === budgets[0].id);
        return credit ? credit.amountRemaining : null;
    }

    private replaceUrl(): void {
        const token = this.cacheService.getContent('pUrlToken', false);
        const search = `?provider=purl&uuid=${this.userService.getUser().uuid}&token=${token}`;
        // Update current url so the user can simply add it to favorite
        this.location.replaceState('/auth' + search);
    }
}
