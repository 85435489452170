import {Component, Inject, OnInit} from '@angular/core';
import {MsServicesGiftService, News} from '@isifid/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {OperationsService} from '../../../../shared/services/operations.service';
import {GiftService} from '../../../../shared/services/gift.service';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
    standalone: false
})
export class NewsComponent implements OnInit {
    news: Array<News> = [];
    lastNewsAt: string = '';
    private operationsTypes: Array<any> = [];

    constructor(
        private readonly giftService: GiftService,
        private readonly msGiftService: MsServicesGiftService,
        private readonly operationsService: OperationsService,
        private readonly dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.getOperationsTypes();
        this.getActiveNews();
    }

    public openNews(news: News): void {
        this.dialog.open(DialogNewsComponent, {data: news});
    }

    private getActiveNews(): void {
        this.msGiftService.getAllNews({status: 'active', clientId: this.giftService.client.id})
            // Filter news by operations types, clientId && excludedClients
            .subscribe((news: Array<News>) => {
                this.news = news.filter((news) => !news.operationTypeId || this.operationsTypes.includes(news.operationTypeId));
                this.news.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1);
                if (this.news.length) this.lastNewsAt = this.news[0].updatedAt;
            });
    }

    private getOperationsTypes() {
        const operations = this.operationsService.getOperations(true, true, false);
        this.operationsTypes = [...new Set(operations.reduce((acc, ope) => acc.concat(ope.operationType.id), []))];
    }

    removeImg(content: string): string {
        return content.replace(/<img[^>]*>/g, '');
    }
}

@Component({
    selector: 'app-dialog-news',
    templateUrl: 'dialog-news.html',
    standalone: false
})
export class DialogNewsComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public news: News
    ) {
    }
}
