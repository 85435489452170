<h4 class="text-center mb-5">
    Questions / réponses
</h4>

<h5>
    I. Gift, connexion et compte
</h5>
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Qu’est-ce que Gift ?
        </mat-expansion-panel-header>
        <p class="small">
            Gift est une plateforme qui vous sert à récompenser vos clients.<br>
            Cela peut être pour différentes raisons sélectionnées par votre direction comme la souscription à certains produits ou la recommandation.<br>
            Les opérations éligibles sont répertoriées dans “Opérations en cours” et dans “Recommandation” pour le parrainage.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Comment me connecter à Gift ?
        </mat-expansion-panel-header>
        <p class="small">
            Pour vous connecter à Gift, il faut entrer votre adresse email professionnelle et valider. <br>
            Vous recevrez un email “Votre accès à Gift” qui contiendra un bouton “Se connecter”.<br>
            Vous serez alors redirigé vers la plateforme Gift, connecté à votre session.<br>
            Attention, les sessions et les liens de connexion Gift sont individuels, chaque collaborateur a son propre accès.
        </p>
        <iframe src="https://www.youtube.com/embed/askXw4u2F_w" title="FAQ - Comment me connecter à Gift ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Comment être reconnecté automatiquement ?
        </mat-expansion-panel-header>
        <p class="small">
            Pour une connexion simplifiée,  il suffit d'ajouter la plateforme à vos favoris et vous serez ainsi automatiquement connecté(e).<br>
            Vous trouverez toutes les instructions sur cette <a class="text-decoration-underline" routerLink="/account/favorite">page</a>
        </p>
        <iframe src="https://www.youtube.com/embed/kU_lQcm-dyk" title="FAQ - Comment être reconnecté automatiquement ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Je ne reçois pas le lien de connexion à Gift ?
        </mat-expansion-panel-header>
        <p class="small">
            Nous vous conseillons d’attendre quelques minutes et de bien vérifier vos SPAM / courriers indésirables.<br>
            Si vous ne recevez rien, contactez notre support par email XXXX&#64;YYYY ou via le tchat à votre disposition (disponible en bas à droite de votre plateforme).
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Je change d’agence, comment modifier mon code ?
        </mat-expansion-panel-header>
        <p class="small">
            Voici <a class="text-decoration-underline" routerLink="/account/user">le lien</a> qui vous permettra de modifier votre code agence.
        </p>
        <iframe src="https://www.youtube.com/embed/dzqYKvGDKBo" title="FAQ - Je change d’agence, comment modifier mon code ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </mat-expansion-panel>
</mat-accordion>

<h5 class="mt-5">
    II. Utiliser les opérations en cours et/ou le Parrainage
</h5>
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Comment récompenser mon client pour une opération en cours ?
        </mat-expansion-panel-header>
        <p class="small">
            Il vous suffit de sélectionner l'opération en cours et de saisir les coordonnées de votre client sur <a class="text-decoration-underline" routerLink="/consumers/tracking">cette page</a>.<br>
            Si la récompense est à consommer sur les sites de e-commerce partenaires, un email et/ou un sms lui donnant accès à sa récompense lui seront envoyés instantanément.
        </p>
        <iframe src="https://www.youtube.com/embed/vKYRZPQjz0U" title="FAQ - Comment récompenser mon client pour une opération en cours ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Comment accéder à mon suivi des récompenses attribuées pour une opération en cours ?
        </mat-expansion-panel-header>
        <p class="small">
            Rendez-vous dans le menu en haut à droite, puis <a class="text-decoration-underline" routerLink="/consumers/tracking">Suivi des Récompenses</a>.<br>
            Vous pourrez sélectionner l’opération que vous souhaitez, ainsi que les dates et accéder à la liste des clients récompensés dans votre agence.
        </p>
        <iframe src="https://www.youtube.com/embed/MhK89B2UzfM" title="FAQ - Comment accéder à mon suivi des récompenses attribuées pour une opération en cours ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Comment inscrire un parrain ?
        </mat-expansion-panel-header>
        <p class="small">
            Il vous suffit de cliquer sur le formulaire <a class="text-decoration-underline" routerLink="/sponsorship/sponsor/invite">Inscrire votre client comme Parrain</a>, sous “Recommandation” ou “Parrainage”.<br>
            Ensuite, vous pouvez inscrire votre client en saisissant son numéro de portable ou bien en lui faisant <a class="text-decoration-underline" routerLink="/sponsorship/qr-code">flasher votre QR code</a>.
        </p>
        <iframe src="https://www.youtube.com/embed/kLh2GPv1RNw" title="FAQ - Comment inscrire un parrain ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Comment retrouver le code parrain d’un client ?
        </mat-expansion-panel-header>
        <p class="small">
            Il vous suffit de rechercher votre client grâce à son N° de portable puis d'accéder à sa fiche client. Le code parrain sera indiqué sur sa fiche.<br>
            Pour rechercher un client, il suffit de cliquer sur le menu en haut à droite de la plateforme, puis <a class="text-decoration-underline" routerLink="/consumers/search">Rechercher un client</a>.<br>
            Lors de la saisie du formulaire filleul, vous devez avoir le code du parrain de votre nouvelle EER ou son numéro de téléphone mobile.
        </p>
        <iframe src="https://www.youtube.com/embed/rL3nZ93SMhI" title="FAQ -  Comment retrouver le code parrain d’un client ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Comment inscrire un filleul ?
        </mat-expansion-panel-header>
        <p class="small">
            Si le filleul est bien entré en relation avec vous (selon les conditions fixées par la direction), vous devez le saisir sur la plateforme Gift afin de déclencher l’envoi des récompenses au parrain et au filleul.<br>
            Il vous suffit de cliquer sur le formulaire <a class="text-decoration-underline" routerLink="/sponsorship/sponsored/check">Accueillir un filleul</a>, sous “Recommandation”. Ensuite, suivez les étapes du formulaire.<br>
            Une fois celui-ci validé, vos clients recevront par SMS et/ou email l’accès à leur récompense.
        </p>
        <iframe src="https://www.youtube.com/embed/nbig40Fpkj0" title="FAQ -  Comment inscrire un filleul ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Comment accéder à mon suivi Parrainage ?
        </mat-expansion-panel-header>
        <p class="small">
            Il vous suffit de cliquer sur <a class="text-decoration-underline" routerLink="/sponsorship/tracking">Suivi Parrainage</a> sous “Recommandation”.<br>
            Vous pourrez sélectionner les dates recherchées et accéder à la liste des parrains et des filleuls inscrits sur votre agence ou seulement par vous.
        </p>
        <iframe src="https://www.youtube.com/embed/6IrmhtStZic" title="FAQ - Comment accéder à mon suivi Parrainage ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Mon client a un numéro de téléphone étranger, comment faire ?
        </mat-expansion-panel-header>
        <p class="small">
            Voici la liste des indicatifs acceptés sur la plateforme Gift, pensez à saisir le numéro en saisissant l’indicatif puis le numéro (exemple pour un numéro belge : +3223740000) :<br>
            +1 États-Unis, Canada<br>
            +32 Belgique<br>
            +34 Espagne<br>
            +39 Italie<br>
            +41 Suisse<br>
            +44 Royaume-Uni<br>
            +49 Allemagne<br>
            +262 La Réunion<br>
            +351 Portugal<br>
            +352 Luxembourg<br>
            +354 Islande<br>
            +508 Saint-Pierre-et-Miquelon<br>
            +590 Guadeloupe<br>
            +594 Guyane française<br>
            +596 Martinique<br>
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Je me suis trompé dans les coordonnées de mon client, comment puis-je procéder ?
        </mat-expansion-panel-header>
        <p class="small">
            Il vous suffit de rechercher votre client grâce à son numéro de portable puis d'accéder à sa fiche client pour mettre à jour ses données. Pensez à valider les nouvelles données en cliquant « Mettre à jour ».<br>
            Appuyez ensuite sur la touche Renvoyer l'accès si besoin.<br>
            Pour rechercher un client, il suffit de cliquer sur le menu en haut à droite de la plateforme, puis <a class="text-decoration-underline" routerLink="/consumers/search">“Rechercher un client”</a>
        </p>
        <iframe src="https://www.youtube.com/embed/DdFXk4d2yUM" title="FAQ - Je me suis trompé dans les coordonnées de mon client, comment puis-je procéder ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Je me suis trompé dans les informations enregistrées et j’ai inscrit un filleul en tant que parrain ?
        </mat-expansion-panel-header>
        <p class="small">
            Veuillez contacter notre support via le <a class="text-decoration-underline" routerLink="/support/contact">formulaire de contact</a> ou le <span class="text-purple text-decoration-underline cursor-pointer" (click)="crispService.toggleSupportChat()">tchat</span> à votre disposition (disponible en bas à droite de votre plateforme).
        </p>
        <iframe src="https://www.youtube.com/embed/sM5ZJvhg3Eg" title="FAQ - Je me suis trompé dans les informations enregistrées et j’ai inscrit un filleul en tant que parrain ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            J’ai récompensé un client qui n’était pas éligible aux conditions de l’offre, comment annuler ?
        </mat-expansion-panel-header>
        <p class="small">
            Veuillez contacter notre support via le <a class="text-decoration-underline" routerLink="/support/contact">formulaire de contact</a> ou le <span class="text-purple text-decoration-underline cursor-pointer" (click)="crispService.toggleSupportChat()">tchat</span> à votre disposition (disponible en bas à droite de votre plateforme).
        </p>
        <iframe src="https://www.youtube.com/embed/sM5ZJvhg3Eg" title="FAQ - J’ai récompensé un client qui n’était pas éligible aux conditions de l’offre, comment annuler ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </mat-expansion-panel>
</mat-accordion>

<h5 class="mt-5">
    III. Les récompenses et les clients
</h5>
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            En quoi consistent les récompenses envoyées aux clients ?
        </mat-expansion-panel-header>
        <p class="small">
            Les récompenses sont toujours exprimées en euros, elles sont en général à consommer dans des sites de e-commerce partenaires (plus de 200 marques).<br>
            Un lien figure en bas de la homepage de Gift. Vous pouvez également cliquer ci-dessous pour découvrir les partenaires : <a href="https://novabank.giftsite.fr/demo" target="_blank" >https://novabank.giftsite.fr/demo</a>
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Mon client n’arrive pas à utiliser sa récompense ?
        </mat-expansion-panel-header>
        <p class="small">
            Il peut se rendre sur la rubrique Assistance sur son site de récompense.<br>
            Il trouvera la réponse soit dans les FAQ soit en envoyant un email au support.<br>
            Mais vous pouvez aussi envoyer un email via le <a class="text-decoration-underline" routerLink="/support/contact">formulaire de contact</a> ou le <span class="text-purple text-decoration-underline cursor-pointer" (click)="crispService.toggleSupportChat()">tchat</span> à votre disposition (disponible en bas à droite de votre plateforme).
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Mon client n’a pas d’adresse email, peut-il être récompensé autrement ?
        </mat-expansion-panel-header>
        <p class="small">
            L'adresse email est obligatoire.<br>
            En effet, nos partenaires sont e-commerçants.<br>
            Il s'agit donc d'une récompense 100% digitale.<br>
            Par email, il pourra se connecter, commander et recevoir ses bons. <br>
            Les bons ne sont pas nominatifs, l’adresse email d’un membre de la famille ou d’un proche peut être utilisée de manière exceptionnelle pour la saisie du client.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Est-ce que mon client peut cumuler plusieurs récompenses ?
        </mat-expansion-panel-header>
        <p class="small">
            Oui, les récompenses sont cumulables, en accord avec les conditions de l’offre décidées par la direction.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Mon client n’a pas reçu son accès à la récompense, comment faire ?
        </mat-expansion-panel-header>
        <p class="small">
            Il vous suffit de rechercher votre client par son numéro de portable pour accéder à sa fiche client puis lui renvoyer ses accès.<br>
            Pour rechercher un client, il suffit de cliquer sur le menu en haut à droite de la plateforme, puis <a class="text-decoration-underline" routerLink="/consumers/search">Rechercher un client</a>.<br>
            Pensez à vérifier que les données saisies et présentes sur la fiche soient correctes.
        </p>
        <iframe src="https://www.youtube.com/embed/ytKqyOWnuEc" title="FAQ - Mon client n’a pas reçu son accès à la récompense, comment faire ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </mat-expansion-panel>
</mat-accordion>

<h5 class="mt-5">
    IV. Nous contacter
</h5>
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Comment vous contacter ?
        </mat-expansion-panel-header>
        <p class="small">
            Si vous n’avez pas trouvé la réponse à votre question, n’hésitez pas à nous contacter.<br>
            Nous sommes disponibles via le formulaire de contact ou le <span class="text-purple text-decoration-underline cursor-pointer" (click)="crispService.toggleSupportChat()">tchat</span> à votre disposition (disponible en bas à droite de votre plateforme).
        </p>
        <iframe src="https://www.youtube.com/embed/sM5ZJvhg3Eg" title="FAQ - Comment vous contacter ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </mat-expansion-panel>
</mat-accordion>