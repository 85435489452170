import { DialogRef } from '@angular/cdk/dialog';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormValidators, GiftUser, MsServicesGiftService, UserService } from '@isifid/core';
import { catchError, finalize, Observable, of } from 'rxjs';
import { GiftService } from 'src/app/shared/services/gift.service';

interface DialogData {
  branchCodes: string[];
  users: Array<{
    giftUser: GiftUser,
    uuid: string
    firstName: string
    lastName: string
    email: string
    branchCode: string
    lastLoggedInAt: string
  }>
}

@Component({
  selector: 'app-dialog-add-advisor',
  templateUrl: './dialog-add-advisor.component.html',
  styleUrl: './dialog-add-advisor.component.scss',
  standalone: false
})
export class DialogAddAdvisorComponent implements OnInit {
  private msServicesGiftService = inject(MsServicesGiftService);
  private giftService = inject(GiftService);
  private userService = inject(UserService);
  private formValidators = inject(FormValidators);
  private destroyRef = inject(DestroyRef);
  data: DialogData = inject(MAT_DIALOG_DATA);

  accessSent: boolean = false;
  loading: boolean = false;
  formGroup = new FormGroup({
    email: new FormControl(this.giftService.settings?.emailAddressRoot || '', [Validators.required, Validators.email, this.formValidators.isEmail, Validators.pattern('^[^0-9]+[0-9]{0,1}(@){1}[^0-9]+'), this.advisorValidatorFn()]),
    branchCode: new FormControl('', [Validators.required])
  });

  ngOnInit(): void {
    this.formGroup.get('branchCode').valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.formGroup.get('email').updateValueAndValidity());
  }

  submit(): void {
    if (this.loading || this.formGroup.invalid) return;
    this.loading = true;
    const advisor = this.data.users.find(s => s.email === this.formGroup.get('email').value);
    if (advisor) {
      advisor.giftUser.branchList.push(parseInt(this.formGroup.get('branchCode').value));
    }

    (
      advisor
        ? this.msServicesGiftService.updateGiftUser(advisor.giftUser)
        : this.invite(this.formGroup.get('email').value)
    )
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => this.loading = false)
      )
      .subscribe(() => this.accessSent = true);
  }

  invite(email): Observable<any> {
      const url = this.giftService.giftNetworkVariables.url || window.location.origin + '/auth';
      const emailSender = this.giftService.giftNetworkVariables.emailSender || null;

      const invitationData = {
          email: email,
          clientId: this.giftService.settings.clientId,
          emailSender,
          url,
          sendAccess: true,
          levelId: +(this.giftService.hierarchicalLevels?.[0].id ?? ''),
          branchList: [this.formGroup.get('branchCode').value],
          managerUuid: this.userService.getUser().uuid
      };

      // Init login for new member
      return this.msServicesGiftService.postInvitation(invitationData).pipe(catchError(() => of(null)));
  }

  private advisorValidatorFn(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const advisor = this.data.users.find(s => s.email === control.value);
      if (advisor?.giftUser.branchList.includes(parseInt(this.formGroup.get('branchCode').value))) {
        return {'exist': true};
      }
      return null;
    }
  }
}
