import {Component} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: false
})
export class NotFoundComponent {
    cdnUrl = environment.cdnUrl + '/gift/img/';
}
