import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {GiftService} from './shared/services/gift.service';
import {BootstrapService} from './shared/services/bootstrap.service';
import {AuthenticationService} from '@isifid/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../environments/environment';
import {UIService} from './shared/services/ui.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: false
})
export class AppComponent implements OnInit {
    isInitialised: boolean = false;
    isAuth: boolean = false;

    constructor(
        private readonly authenticationService: AuthenticationService,
        public readonly bootstrapService: BootstrapService,
        private readonly giftService: GiftService,
        private readonly matSnackBar: MatSnackBar,
        public readonly router: Router,
        public uiService: UIService
    ) {
        // Make sure the account is complete when we navigate
        router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe({
                next: (e: NavigationEnd) => {
                    // Close snack bar if still open
                    this.matSnackBar.dismiss();

                    // Only do something when the user is init
                    if (!this.isInitialised) return;

                    // Force user to set up the account if incomplete
                    if (!giftService.isAccountComplete && e.url.indexOf('/account/user') == -1) this.router.navigate(['/account/user']).then();
                    // else if (!giftService.isManagerAccountComplete && e.url.indexOf('/account/staff') == -1) this.router.navigate(['/account/staff']).then();
                }
            });
    }

    ngOnInit() {
        this.bootstrapService.isInitialised.subscribe({
            next: (isInitialised) => this.isInitialised = isInitialised
        });

        this.authenticationService.isAuth.subscribe({
            next: (isAuth) => this.isAuth = isAuth
        });
    }

    isAppReady() {
        if (this.isInitialised) {
            // Make sure the account is complete when we load the page
            if (!this.giftService.isAccountComplete && this.router.url.indexOf('/account/user') == -1) {
                this.router.navigate(['/account/user']).then();
            // } else if (!this.giftService.isManagerAccountComplete && this.router.url.indexOf('/account/staff') == -1) {
            //     this.router.navigate(['/account/staff']).then();
            } else return true;
        }
        if (!this.isAuth) return true;
        return false;
    }

    protected readonly environment = environment;
}
