import {Component} from '@angular/core';
import {CrispService, UserService} from '@isifid/core';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
    standalone: false
})
export class SupportComponent {
    constructor(
        public readonly userService: UserService,
        public readonly crispService: CrispService
    ) {
    }
}
