import { Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormField, MatLabel, MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService, FormValidators, UserCached, UserService } from '@isifid/core';
import { faq } from 'src/app/shared/models/faq.model';
import { GiftService } from 'src/app/shared/services/gift.service';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [ReactiveFormsModule, MatFormField, MatInputModule, MatLabel, MatSelectModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class SupportContactComponent {
  faq = faq;
  loading: boolean = true;
  subjectIndex: number;
  supportForm: FormGroup;
  private user: UserCached;
  private readonly endpoint = '/services/gift/support';

  constructor(
      private readonly apiService: ApiService,
      private readonly formBuilder: FormBuilder,
      private readonly formValidators: FormValidators,
      private readonly giftService: GiftService,
      private readonly userService: UserService,
      private readonly _snackBar: MatSnackBar,
      private readonly destroyRef: DestroyRef
  ) {
  }

  ngOnInit() {
      let domain: string;
      if (this.giftService.client.configuration) domain = JSON.parse(this.giftService.client.configuration)?.demoDomain ?? this.giftService.client.domain;
      else domain = this.giftService.client.domain;

      this.user = this.userService.getUser();
      this.supportForm = this.formBuilder.group({
          message: ['', Validators.required]
      });
      this.loading = false;
  }

  submit(): void {
      if (this.supportForm.invalid) return;
      this.loading = true;
      this.sendMailSupport(this.supportForm.getRawValue());
  }

  private sendMailSupport({message}): void {
    const name = this.user.firstName + ' ' + this.user.lastName;
    const support = {
        subject: 'Nouveau message Gift',
        email: this.user.email,
        name,
        message: message + '   Message envoyé par : ' + name + ' email : ' + this.user.email
    };
    this.apiService.post(`${this.endpoint}`, support)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
            next: () => {
                // Don't reset the whole form
                this.supportForm.get('message').patchValue('');
                this._snackBar.open('Email envoyé', 'X');
            },
            error: () => {
                console.error('Error while sending email to support');
                this._snackBar.open('Une erreur est survenue, merci de réessayer', 'X');
                this.loading = false;
            }, complete: () => this.loading = false
        });
  }
}
