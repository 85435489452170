import {Component, OnInit} from '@angular/core';
import {Consumer, DateService, MsConsumersService, Offer, Operation, Reward, SponsorshipUser} from '@isifid/core';
import {ConsumerService} from '../../../shared/services/consumer.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SponsorshipService} from '../../../shared/services/sponsorship.service';
import {catchError, concat, finalize, forkJoin, of, take, toArray} from 'rxjs';
import {GiftService} from '../../../shared/services/gift.service';
import {OperationsService} from '../../../shared/services/operations.service';

@Component({
    selector: 'app-reward',
    templateUrl: './reward.component.html',
    standalone: false
})
export class RewardComponent implements OnInit {
    consumer: Consumer = null;
    consumerCanBeRewarded = {operationCheckFail: false, offerCheckFail: false, budgetCheckFail: false};
    consumerIsRewarded = false;
    loading = true;
    consumerOffers: Offer[] = [];
    offer: Offer = null;
    operation: Operation = null;
    resetConsumerForm: boolean;
    rewarding: boolean;
    sponsorshipUser: SponsorshipUser;
    private consumerRewards: Reward[] = [];
    private rewardProperties = [];

    constructor(
        public readonly sponsorshipService: SponsorshipService,
        private readonly consumerService: ConsumerService,
        private readonly msConsumersService: MsConsumersService,
        private readonly snackBar: MatSnackBar,
        private readonly giftService: GiftService,
        private readonly dateService: DateService,
        private readonly operationsService: OperationsService
    ) {
    }

    ngOnInit() {
        // Get the operationId
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('operationId')) {
            this.operation = new Operation();
            this.operation.id = urlParams.get('operationId');
        }
        // Get the offerId
        if (urlParams.get('offerId')) {
            this.offer = new Offer();
            this.offer.id = urlParams.get('offerId');
        }
        this.loading = false;
    }

    get isSponsor(): boolean {
        return !!(this.sponsorshipUser?.code);
    }

    get isSponsored() {
        if (!this.sponsorshipUser) return false;
        return !!this.sponsorshipUser.sponsorConsumerId;
    }

    get canDisplayBecomeSponsorButton() {
        if (this.isSponsored) {
            const sponsoredReward = this.consumerRewards.find((r) => r.operationId === this.sponsorshipService.settings.operationId &&
                this.getOfferName(r.offerId)?.includes('filleul'));
            const canSponsoredBecomeSponsorAfterDays = this.giftService.getConfigurationValue('canSponsoredBecomeSponsorAfterDays');

            if (!sponsoredReward || !canSponsoredBecomeSponsorAfterDays) return true;
            else return this.dateService.getDatesDiff(sponsoredReward.createdAt, new Date(), 'days') >= Number(canSponsoredBecomeSponsorAfterDays);
        } else return true;
    }

    updateOperation($event: Operation) {
        this.operation = $event;
        this.rewarding = false;
        this.getOffers();
        if (this.consumer) this.checkEligibilityOperations();
    }

    updateOffer($event: Offer) {
        this.offer = $event;
        this.rewarding = false;
        if (this.consumer) this.checkEligibilityOffer();
    }

    updateCanRewardWithCredit($event: boolean) {
        if ($event !== undefined) this.consumerCanBeRewarded.budgetCheckFail = !$event;
    }

    updateConsumer(event: { consumer: Consumer; rewardProperties: any[] }) {
        this.rewarding = false;
        this.consumer = event.consumer;
        this.rewardProperties = event.rewardProperties;
        this.getRewards();
    }

    updateSponsorshipUser(s: SponsorshipUser): void {
        this.sponsorshipUser = s;
    }

    isValid() {
        return !!(this.consumer && this.offer && this.operation && !this.consumerCanBeRewarded.offerCheckFail && !this.consumerCanBeRewarded.operationCheckFail
                && !this.consumerCanBeRewarded.budgetCheckFail);
    }

    private getOfferName(offerId: number) {
        return this.consumerOffers.find(o => o.id as unknown as number === offerId)?.name;
    }

    private checkEligibilityOperations(): void {
        if (!this.consumer?.id) this.consumerCanBeRewarded.operationCheckFail = true;
        else {
            // Check if consumer is eligible for operation
            // Count reward for operation
            const count = this.consumerRewards.filter((r: Reward) => r.operationId === this.operation.id).length;
            // If maxRewardPerConsumer is set, check if consumer has already reached the limit
            this.consumerCanBeRewarded.operationCheckFail = !!(this.operation.maxRewardPerConsumer && count>0 && count >= +this.operation.maxRewardPerConsumer);
        }
    }

    private checkEligibilityOffer(): void {
        if (!this.offer) this.consumerCanBeRewarded.offerCheckFail = true;
        else {
            const count = this.consumerRewards.filter((r: Reward) => r.offerId === this.offer.id).length;
            this.consumerCanBeRewarded.offerCheckFail = !!(this.offer.maxRewardPerConsumer && count >= +this.offer.maxRewardPerConsumer);
        }
    }

    private reward() {
        if (!this.isValid()) return;
        this.loading = true;
        forkJoin([
            this.sponsorshipService.getSponsorshipUserByConsumerId(this.consumer.id).pipe(take(1), catchError(() => of(null))),
            this.consumerService.rewardOne(this.consumer, this.offer, this.rewardProperties)
        ])
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: ([sponsorshipUser, r]) => {
                    this.sponsorshipUser = sponsorshipUser;
                    this.consumerIsRewarded = r.nbOfNonRewarded !== 1;
                },
                error: () => {
                    this.snackBar.open('Une erreur s\'est produite', 'X');
                    console.error('Error while rewarding consumer ', this.consumer.id);
                },
                complete: () => {
                    this.operation.rewardTypeId !== 10 ?
                        this.snackBar.open('Votre client va recevoir immédiatement un accès à sa récompense, par email et par SMS.', 'X') :
                        this.snackBar.open('La demande de récompense de votre client a bien été enregistrée. Sa récompense lui parviendra prochainement.', 'X');
                }
            });
    }

    // Get rewards for consumer and check eligibility and reward if valid
    private getRewards() {
        this.consumerRewards = [];
        if (this.consumer?.id) {
            this.msConsumersService.getRewards(this.consumer.id).subscribe({
                next: (rewards) => this.consumerRewards = rewards,
                error: () => console.error('Error while getting rewards for consumer ', this.consumer.id),
                complete: () => {
                    this.checkEligibilityOperations();
                    this.checkEligibilityOffer();
                    this.rewarding = true;
                    if (this.isValid()) this.reward();
                }
            });
        }
    }

    private getOffers(): void {
        this.consumerOffers = [];
        const operationIds = this.consumerRewards.map(reward => reward.operationId).filter(s => s);
        const observers = operationIds.map(s => this.operationsService.getOffersByOperationId(s));
        concat(...observers)
            .pipe(toArray())
            .subscribe({
                next: offers => this.consumerOffers = offers.flat()
            });
    }

    resetOfferAndConsumer() {
        this.resetConsumerForm = true;
        this.consumerIsRewarded = false;
        this.updateOffer(null);
        this.updateOffer(this.offer);
        this.rewarding = false;
    }
}
