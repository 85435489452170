import { Component } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterLink } from '@angular/router';
import { CrispService } from '@isifid/core';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [MatExpansionModule, RouterLink],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class SupportFaqComponent {
  constructor(
    public readonly crispService: CrispService
  ) {}
}
