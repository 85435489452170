<h4 class="text-center mb-5">
    Démo de la plateforme
</h4>
<div class="row">
    <div class="col text-center">
        <iframe src="https://www.youtube.com/embed/MHN0nKoweFc" title="Tuto Gift : Votre parcours conseiller (opération)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        
        <h5>Récompense d'un client</h5>
    </div>
    <div class="col text-center">
        <iframe src="https://www.youtube.com/embed/PQ1iZdMTbCo" title="Tuto Gift : Votre parcours conseiller (parrainage)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <h5>Parcours de parrainage</h5>
    </div>
</div>