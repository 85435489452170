<div class="container-fluid bg-custom p-2 p-md-5 min-vh-100 max-vw-100 d-flex gap-5">
    <div class="p-3 pb-5 h-fit">
        <div class="need-help-section">
            <div class="need-help">
                Besoin d'aide ?
            </div>

            <ul class="list-style-none">
                <li class="pb-2">
                    <span>
                        <img src="/assets/icons/demo.svg" />
                    </span>
                    <a routerLink="/support/demo" routerLinkActive="text-purple">Démo de la plateforme</a>
                </li>
                @if (userService.hasRole('GIFT_MANAGER')) {
                    <li class="py-2">
                        <span>
                            <img src="/assets/icons/handshake.svg" />
                        </span>
                        <a routerLink="/support/nos-conseils" routerLinkActive="text-purple">Nos conseils pour un parrainage réussi</a>
                    </li>
                }
                <li class="pt-2">
                    <span>
                        <img src="/assets/icons/faq.svg" />
                    </span>
                    <a routerLink="/support/faq" routerLinkActive="text-purple">FAQ</a>
                </li>
            </ul>
        </div>

        <div class="contact-section">
            <div class="contact">
                <span class="title-border"></span>
                D’autres questions ?<br>
                <span class="sub-title">
                    Écrivez-nous ou discutez par chat.
                </span>
            </div>
            <ul class="list-style-none">
                <li class="pb-2">
                    <span>
                        <img class="contact-option-img" src="/assets/icons/send.svg" />
                    </span>
                    <a routerLink="/support/contact" routerLinkActive="text-purple" >Remplir le formulaire</a>
                </li>
                <li class="chat-btn pt-3" (click)="crispService.toggleSupportChat()">
                    <span>
                        <img class="contact-option-img" src="/assets/icons/chat.svg" />
                    </span>
                    Par chat
                </li>
            </ul>
        </div>
    </div>

    <div class="w-100 p-5">
        <router-outlet></router-outlet>
    </div>
</div>
