import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {CacheService, Consumer, DateService, FormValidators, GiftUser, MobileService, MsConsumersService, Reward, SponsorshipUser} from '@isifid/core';
import {concat, forkJoin, toArray} from 'rxjs';
import {filter} from 'rxjs/operators';

import {OperationsService} from '../../../shared/services/operations.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../../environments/environment';
import {SponsorshipService} from '../../../shared/services/sponsorship.service';
import {UIService} from '../../../shared/services/ui.service';
import {GiftService} from '../../../shared/services/gift.service';
import {GiftUserService} from '../../../shared/services/gift-user.service';

@Component({
    selector: 'app-consumers-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
    standalone: false
})
export class AccountComponent implements OnInit {
    accessSent = {marketplace: false, sponsorship: false};
    checkAge: boolean = false;
    checkAgeForm: FormGroup;
    consumer: Consumer;
    consumerForm: FormGroup;
    rewards: Array<Reward>;
    sponsorshipUrl: string;
    sponsorshipUser: SponsorshipUser;
    private offers: any[];
    private giftUser: GiftUser;

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly giftUserService: GiftUserService,
        private readonly cacheService: CacheService,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private readonly mobileService: MobileService,
        private readonly msConsumersService: MsConsumersService,
        private readonly operationsService: OperationsService,
        readonly uiService: UIService,
        private readonly _snackBar: MatSnackBar,
        public readonly sponsorshipService: SponsorshipService,
        private readonly giftService: GiftService,
        private readonly dateService: DateService
    ) {
    }

    ngOnInit(): void {
        this.rewards = [];
        const id = this.activatedRoute.snapshot.paramMap.get('id');

        this.giftUserService.getGiftUser().pipe(filter(s => !!s)).subscribe(s => this.giftUser = s);

        // Outside forkJoin to avoid error if 404
        this.sponsorshipService.getSponsorshipUserByConsumerId(Number(id)).subscribe({
            next: s => this.sponsorshipUser = s
        });

        forkJoin([
            this.msConsumersService.getConsumer(id),
            this.msConsumersService.getRewards(id)
        ]).subscribe({
            next: ([consumer, rewards]) => {
                this.consumer = consumer;
                this.rewards = rewards.filter(r => r.amount > 0 && r.offerId);
                this.rewards.sort((a, b) => new Date(b.expiresAt).getTime() - new Date(a.expiresAt).getTime());

                // Get offers name for rewards
                this.offers = [];
                const operationIdFromReward = [...new Set(this.rewards.map(reward => reward.operationId))];
                if (operationIdFromReward.length) {
                    const observers = operationIdFromReward.map(s => this.operationsService.getOffersByOperationId(s));
                    concat(...observers)
                        .pipe(toArray())
                        .subscribe({
                            next: offers => this.offers = offers.flat()
                        });
                }

                this.consumerForm = this.formBuilder.group({
                    email: [this.consumer.email, [Validators.required, Validators.email, this.formValidators.isEmail]],
                    mobile: [this.consumer.mobile, this.formValidators.isInternationalPhone]
                });
            },
            error: () => console.error('error getting consumer or rewards')
        });
        if (this.sponsorshipService.hasSponsorship) {
            this.checkAgeForm = this.formBuilder.group({checkAge: [null, Validators.requiredTrue]});
            this.sponsorshipUrl = this.getSponsorshipUrl(id);
        }
    }

    saveConsumerField(field: string) {
        let data;
        switch (field) {
        case 'email':
            if (!this.consumerForm.get('email').valid || this.consumerForm.get('email').value === this.consumer.email) return;
            data = {email: this.consumerForm.get('email').value};
            break;
        case 'mobile':
            if (!this.consumerForm.get('mobile').valid || this.consumerForm.get('mobile').value === this.consumer.mobile) return;
            data = {mobile: this.mobileService.formatMobile(this.consumerForm.get('mobile').value)};
            break;
        }
        this.msConsumersService.updateConsumer(this.consumer.id, data).subscribe({
            next: (consumer: Consumer) => this.consumer = consumer,
            error: () => console.error('error updating consumer'),
            complete: () => this._snackBar.open('Client mis à jour', 'X')
        });
    }

    isSponsor(): boolean {
        return !!(this.sponsorshipUser?.code);
    }

    isActiveSponsor(): boolean {
        if (!this.isSponsor()) return false;
        const sponsorReward = this.rewards.find((r) => r.operationId === this.sponsorshipService.settings.operationId &&
            this.getOfferName(r.offerId)?.includes('parrain'));
        return !!sponsorReward;
    }

    isSponsored() {
        if (!this.sponsorshipUser) return false;
        return !!this.sponsorshipUser.sponsorConsumerId;
    }

    isCashReward(rewardTypeId: number) {
        return rewardTypeId === 10;
    }

    getSponsorCode() {
        return this.sponsorshipUser.codeCustomised ? this.sponsorshipUser.codeCustomised : this.sponsorshipUser.code;
    }

    sendAccess(): void {
        this.accessSent.marketplace = true;
        this.msConsumersService.getAccess(this.consumer.id, 'email').subscribe();
    }

    createSponsorshipUser(checkAge?: boolean): void {
        // Under some conditions, we need to check the sponsor age
        if (this.sponsorshipService.settings.sponsorAgeMin &&
            this.sponsorshipService.settings.sponsorAgeMax &&
            this.sponsorshipService.settings.sponsorAgeMax < 100) {
            if (!checkAge) {
                this.checkAge = true;
                return;
            }
        }

        this.sponsorshipService.becomeSponsorshipUser(this.consumer, this.sponsorshipUser)
            .subscribe({complete: () => this.ngOnInit()});
    }

    submit() {
        if (this.checkAgeForm.valid) this.createSponsorshipUser(true);
    }

    hasExpired(expireAt): boolean {
        const now = new Date().getTime();
        const expiration = new Date(expireAt).getTime();
        return now > expiration;
    }

    hasActiveReward(): boolean {
        return !!this.rewards.filter(r => r.status === 'active').length;
    }

    canDisplayBecomeSponsorButton() {
        if (this.isSponsored()) {
            const sponsoredReward = this.rewards.find((r) => r.operationId === this.sponsorshipService.settings.operationId &&
                this.getOfferName(r.offerId)?.includes('filleul'));
            const canSponsoredBecomeSponsorAfterDays = this.giftService.getConfigurationValue('canSponsoredBecomeSponsorAfterDays');

            if (!sponsoredReward || !canSponsoredBecomeSponsorAfterDays) return true;
            else return this.dateService.getDatesDiff(sponsoredReward.createdAt, new Date(), 'days') >= Number(canSponsoredBecomeSponsorAfterDays);
        } else return true;
    }

    sendSponsorAccess(): void {
        this.accessSent.sponsorship = true;
        this.sponsorshipService.sendSponsorAccess(this.consumer.id).subscribe();
    }

    getOfferName(offerId: number) {
        return this.offers.find(o => o.id === offerId)?.name;
    }

    private getSponsorshipUrl(id): string {
        let url = this.sponsorshipService.settings.domain;
        if (!url) url = environment.domain;
        const token = this.cacheService.getContent('refresh_token', false);
        if (url.indexOf('https://') === -1) url = `https://${url}`;
        return `${url}/auth?token=${token}&consumerId=${id}&settingsId=${this.sponsorshipService.settings.id}&provider=gift`;
    }
}
